import dayJs from 'dayjs';

import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { yup } from '@app/formik';
import { USA_STATES } from '@app/user/constants/usaStates';
import { COUNTRIES } from '@app/application/constants/countries';
import { SexualOrientationEnum } from '@app/application/constants';
import {
  EnglishLevelEnum,
  GenderIdentityEnum,
  PronounsEnum,
  QuestionnaireDiagnosisEnum,
  QuestionnaireRelationshipStatusEnum,
  QuestionnaireSurveyEnum,
} from '../constants';
import cardImage from '../images/card-questionnaire-second-intended-parent.jpg';

export const secondIntendedParent: DynamicFormConfigSurvey<QuestionnaireSurveyEnum> = {
  name: QuestionnaireSurveyEnum.secondIntendedParent,
  meta: {
    cardImage,
    dependOnFields: ['status', 'isPartnerInformation', 'partner.firstName'],
  },
  visibleIf: ({ isPartnerInformation }) => Boolean(isPartnerInformation),
  pages: [
    {
      name: `${QuestionnaireSurveyEnum.secondIntendedParent}.information`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            variables: {
              time: '5-10',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part1`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part1`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'partner.name',
      alias: 'name',
      elements: [
        {
          name: 'partner.firstName',
          alias: 'firstName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
        {
          optional: true,
          name: 'partner.middleName',
          alias: 'middleName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
            withLockIcon: true,
          },
        },
        {
          name: 'partner.lastName',
          alias: 'lastName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
            withLockIcon: true,
          },
        },
        {
          optional: true,
          name: 'partner.nickname',
          alias: 'nickname',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'partner.cellPhone',
      alias: 'cellPhone',
      elements: [
        {
          name: 'partner.phone',
          alias: 'phone',
          type: DynamicFormElementType.phone,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            disableDropdown: false,
            forceCallingCode: false,
            withLockIcon: true,
            length: 13,
          },
        },
      ],
    },
    {
      name: 'partner.email',
      alias: 'email',
      elements: [
        {
          name: 'partner.email',
          alias: 'contactEmail',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => yup.string().email().isValidSync(value),
          meta: {
            pdf: {},
          },
          props: {
            withLockIcon: true,
            capitalize: false,
          },
        },
      ],
    },
    {
      name: 'dateOfBirthAndAge',
      elements: [
        {
          name: 'partner.dateOfBirth',
          alias: 'dateOfBirth',
          type: DynamicFormElementType.date,
          meta: {
            pdf: {},
          },
          props: {
            withLockIcon: true,
            openTo: 'year',
            minDate: dayJs().subtract(50, 'years'),
            maxDate: dayJs().subtract(10, 'years'),
            views: ['year', 'month', 'day'],
          },
        },
        {
          optional: true,
          name: 'partner.age',
          alias: 'age',
          type: DynamicFormElementType.computedText,
          meta: {
            pdf: {},
          },
          props: {
            disabled: true,
            formula: ({ partner }) => {
              return partner.dateOfBirth && partner.dateOfBirth !== 'Invalid Date'
                ? dayJs().diff(dayJs(partner.dateOfBirth).subtract(1, 'day'), 'y')
                : '-';
            },
          },
        },
      ],
    },
    {
      name: 'place',
      elements: [
        {
          name: 'partner.placeOfBirth',
          alias: 'placeOfBirth',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'address',
      elements: [
        {
          name: 'partner.isAddressSameWithPartner',
          alias: 'isAddressSameWithPartner',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
        },
        {
          name: 'partner.country',
          alias: 'country',
          type: DynamicFormElementType.autocomplete,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => !Boolean(partner.isAddressSameWithPartner),
          meta: {
            pdf: {},
          },
          props: {
            options: COUNTRIES,
          },
        },
        {
          name: 'partner.state',
          alias: 'state',
          type: DynamicFormElementType.autocomplete,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => !Boolean(partner.isAddressSameWithPartner) && partner.country === 'US',
          meta: {
            pdf: {},
          },
          props: {
            options: USA_STATES,
          },
        },
        {
          name: 'partner.fullAdress',
          alias: 'fullAdress',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => !Boolean(partner.isAddressSameWithPartner),
          meta: {
            pdf: {},
          },
          props: {
            withLockIcon: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'employmentStatus',
      elements: [
        {
          name: 'partner.isEmployed',
          alias: 'isEmployed',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
        },
        {
          name: 'partner.occupation',
          alias: 'occupation',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.isEmployed),
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
        {
          name: 'partner.employer',
          alias: 'employer',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.isEmployed),
          meta: {
            pdf: {},
          },
          props: {
            withLockIcon: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'english',
      elements: [
        {
          name: 'partner.englishLevel',
          alias: 'englishLevel',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.half,
          meta: {
            pdf: {},
          },
          props: {
            enum: EnglishLevelEnum,
          },
        },
        {
          name: 'partner.languages',
          alias: 'languages',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'gender',
      elements: [
        {
          name: 'partner.genderIdentity',
          alias: 'genderIdentity',
          showDescription: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            enum: GenderIdentityEnum,
          },
        },
        {
          name: 'partner.genderIdentityDescription',
          alias: 'genderIdentityDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner.genderIdentity === GenderIdentityEnum.other,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
        {
          name: 'partner.pronouns',
          alias: 'pronouns',
          showDescription: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            enum: PronounsEnum,
          },
        },
        {
          name: 'partner.pronounsDescription',
          alias: 'pronounsDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner.pronouns === PronounsEnum.other,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'sexual',
      elements: [
        {
          name: 'partner.sexualOrientation',
          alias: 'sexualOrientation',
          showDescription: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            enum: SexualOrientationEnum,
          },
        },
        {
          name: 'partner.sexualOrientationDescription',
          alias: 'sexualOrientationDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner.sexualOrientation === SexualOrientationEnum.other,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'relationship',
      elements: [
        {
          name: 'partner.relationshipStatus',
          alias: 'relationshipStatus',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            enum: QuestionnaireRelationshipStatusEnum,
          },
        },
        {
          name: 'partner.relationshipStatusDescription',
          alias: 'relationshipStatusDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner.relationshipStatus === QuestionnaireRelationshipStatusEnum.other,
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'isAnyOtherChildren',
      elements: [
        {
          noLabel: true,
          name: 'partner.isAnyOtherChildren',
          alias: 'isAnyOtherChildren',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            defaultChecked: false,
          },
        },
        {
          name: 'partner.otherChildren',
          alias: 'otherChildren',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.isAnyOtherChildren),
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'isArrested',
      elements: [
        {
          noLabel: true,
          name: 'partner.isArrested',
          alias: 'isArrested',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            defaultChecked: false,
          },
        },
        {
          name: 'partner.arrestDescription',
          alias: 'arrestDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.isArrested),
          meta: {
            pdf: {},
          },
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part2`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part2`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'personalityDescription',
      elements: [
        {
          noLabel: true,
          name: 'partner.personalityDescription',
          alias: 'personalityDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'interests',
      elements: [
        {
          noLabel: true,
          name: 'partner.interests',
          alias: 'interests',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'favoriteGeneral',
      elements: [
        {
          noLabel: true,
          name: 'partner.favoriteGeneral',
          alias: 'favoriteGeneral',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'favoriteFood',
      elements: [
        {
          noLabel: true,
          name: 'partner.favoriteFood',
          alias: 'favoriteFood',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'religion',
      elements: [
        {
          noLabel: true,
          name: 'partner.religion',
          alias: 'religion',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part3`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part3`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header', ['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'hasHospitalizedForPsychiatric',
      elements: [
        {
          noLabel: true,
          name: 'partner.hasHospitalizedForPsychiatric',
          alias: 'hasHospitalizedForPsychiatric',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            reversed: true,
          },
        },
        {
          name: 'partner.hospitalizationDescription',
          alias: 'hospitalizationDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.hasHospitalizedForPsychiatric),
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'haveAlcoholDrugIllnessHistory',
      elements: [
        {
          noLabel: true,
          name: 'partner.haveAlcoholDrugIllnessHistory',
          alias: 'haveAlcoholDrugIllnessHistory',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            reversed: true,
          },
        },
        {
          name: 'partner.alcoholDrugIllnessDescription',
          alias: 'alcoholDrugIllnessDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.haveAlcoholDrugIllnessHistory),
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'haveHealthIssues',
      elements: [
        {
          noLabel: true,
          name: 'partner.haveHealthIssues',
          alias: 'haveHealthIssues',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            reversed: true,
          },
        },
        {
          name: 'partner.healthIssuesDescription',
          alias: 'healthIssuesDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner.haveHealthIssues),
          props: {
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'yourDiagnosis',
      elements: [
        {
          name: 'partnerDiagnosis',
          alias: 'yourDiagnosis',
          type: DynamicFormElementType.toggleButtonGroup,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            json: true,
            pdf: {},
          },
          props: {
            translation: 'questionnaire',
            options: Object.values(QuestionnaireDiagnosisEnum),
          },
        },
        {
          name: 'partnerDiagnosisDetails',
          alias: 'yourDiagnosisDetails',
          type: DynamicFormElementType.text,
          visibleIf: ({ partnerDiagnosis }) => partnerDiagnosis && Object.values(partnerDiagnosis).some(Boolean),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {},
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
  ],
};
