import { useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, Paper, Typography, InputLabel, Button } from '@mui/material';

import { get } from '@app/app/utils/get';
import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { Label } from '@app/ui/label';
import { Loader } from '@app/ui/loader';
import { useModal } from '@app/ui/modal';
import { USER_STATUS_COLOR, generalInfoItems, questionnaireGeneralInfoItem } from '@app/user/constants';

import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { questionnaireGetOne } from '@app/questionnaire/gql';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { QuestionnaireList } from '@app/questionnaire/components/item/QuestionnaireList';
import { QuestionnaireUploadForm } from '@app/questionnaire/components/upload/QuestionnaireUploadForm';
import { ApplicationList } from './ApplicationList';
import { UploadForm } from '../upload/UploadForm';
import { ExportPdfDropdown } from '../pdf/ExportPdfDropdown';
import { UpdatePdfCoverModal } from '../pdf/UpdatePdfCoverModal';
import { Application } from '../../types/Application';
import { applicationGetOne } from '../../gql';
import { applicationConfig } from '../../configs';
import { getFieldsFromSurveys } from '../../utils/survey';

export const AdminApplicationItem = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const routerParams = useParams();
  const isMobile = useIsMobile();
  const { isOpen, close, show } = useModal();
  const { entity, ...params } = useParams<{ id?: string; entity: 'surrogates' | 'parent' }>();
  const isSurrogates = useMemo(() => entity === 'surrogates', [entity]);

  const id = Number(params.id);

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys((isSurrogates ? applicationConfig : questionnaireConfig).surveys);

    return [...surveyFields, 'status', 'user.email'];
  }, [isSurrogates]);

  const { data, loading } = useQuery<{ applicationGetOne?: Application; questionnaireGetOne?: Questionnaire }>(
    isSurrogates ? applicationGetOne(fields) : questionnaireGetOne(fields),
    {
      variables: { id },
    },
  );
  const normalizedData = isSurrogates ? data?.applicationGetOne : data?.questionnaireGetOne;

  const userName = `${normalizedData?.firstName || ''} ${normalizedData?.lastName || ''}`.trim();

  const breadcrumbs = useMemo(
    () => [
      {
        title: t(`user.page.${isSurrogates ? 'adminList' : 'parentAdminList'}.title`),
        to: `/admin/${entity}`,
      },
      {
        title: userName,
      },
    ],
    [t, userName, isSurrogates, entity],
  );

  const handleSurveyClick = useCallback(
    (survey: string) => navigate(`/admin/${routerParams.entity}/${id}/${survey}`),
    [id, routerParams],
  );

  return (
    <>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h1">{userName}</Typography>
        {normalizedData?.status && (
          <Label
            sx={{ ml: 2, mt: 0.5, textTransform: 'capitalize' }}
            text={t(`${isSurrogates ? 'application' : 'questionnaire'}.field.status.option.${normalizedData.status}`)}
            color={USER_STATUS_COLOR[normalizedData.status]}
          />
        )}
      </Box>

      <Paper
        sx={{
          wordWrap: 'break-word',
          borderRadius: {
            md: '20px',
          },
          p: {
            xs: 2,
            sm: 3,
            lg: 5,
          },
          mb: 5,
        }}
        variant="outlined"
        elevation={0}
      >
        <Typography variant="h3" sx={{ mb: 3 }}>
          {t('user.page.single.information')}
        </Typography>

        <Grid container direction={isMobile ? 'column' : 'row'}>
          {normalizedData &&
            (isSurrogates ? generalInfoItems : questionnaireGeneralInfoItem).map((item, index) => {
              const name = item.split('.').pop();
              const lastChild = index === generalInfoItems.length - 1;

              return (
                <Fragment key={item}>
                  <Grid item xs md={lastChild ? 3 : 2}>
                    <InputLabel sx={{ mb: 1.5 }}>
                      {t(`${isSurrogates ? 'application' : 'questionnaire'}.field.${name}.label`)}
                    </InputLabel>
                    <div>{get(normalizedData || {}, item) || '-'}</div>
                  </Grid>

                  {!lastChild && (
                    <Divider
                      sx={{
                        my: {
                          xs: 1,
                          sm: 0,
                        },
                        mx: {
                          sm: 2,
                          lg: 3,
                        },
                      }}
                      orientation={isMobile ? 'horizontal' : 'vertical'}
                      flexItem
                    />
                  )}
                </Fragment>
              );
            })}
        </Grid>
      </Paper>

      <Box
        mb={3}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">{t('user.page.single.subtitle')}</Typography>

        <Box
          sx={{
            display: 'flex',
            gap: {
              xs: 2,
              md: 3,
            },
            flexWrap: 'wrap',
          }}
        >
          <Button className="filledTonal" color="primary" variant="contained" onClick={show}>
            {t('application.button.updateProfilePdf.name')}
          </Button>
          <ExportPdfDropdown applicationId={id} label={t('application.button.exportPdf.name')} arrow />
        </Box>
      </Box>

      {isOpen && <UpdatePdfCoverModal id={id} open={isOpen} onClose={close} />}

      {data?.applicationGetOne && isSurrogates && (
        <ApplicationList item={data.applicationGetOne} onClick={handleSurveyClick} isAdmin />
      )}

      {data?.questionnaireGetOne && !isSurrogates && (
        <QuestionnaireList item={data.questionnaireGetOne} onClick={handleSurveyClick} isAdmin />
      )}

      <Box mt={4}>
        <Typography variant="h2" mb={3}>
          {t('application.field.photos.label')}
        </Typography>
        <Paper
          sx={{
            p: {
              xs: 2,
              sm: 3,
              lg: 5,
            },
            borderRadius: '20px',
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography variant="h4" mb={3}>
            {t('application.field.photos.subtitle')}
          </Typography>

          {data?.applicationGetOne && <UploadForm applicationId={id} />}
          {data?.questionnaireGetOne && <QuestionnaireUploadForm questionnaireId={id} />}
        </Paper>
      </Box>

      <Loader isLoading={loading} />
    </>
  );
};
