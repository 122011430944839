import { useCallback, useMemo } from 'react';
import { camelCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { DynamicForm } from '@app/dynamic';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { Breadcrumbs } from '@app/ui/breadcrumbs';

import { useUpdateQuestionnaire } from '@app/questionnaire/hooks/useUpdateQuestionnaire';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { QuestionnaireSurveyEnum } from '@app/questionnaire/constants';
import { getName } from '../utils';
import defaultImageCard from '../images/card-application-personal-info.jpg';
import { useUpdateApplication } from '../hooks/useUpdateApplication';
import { applicationConfig } from '../configs';

export const AdminApplicationForm = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const {
    id,
    survey: surveyParam,
    entity,
  } = useParams<{ id?: string; survey: string; entity: 'surrogates' | 'parent' }>();
  const isSurrogates = useMemo(() => entity === 'surrogates', [entity]);
  const survey = camelCase(surveyParam as string);

  const handleBack = useCallback(() => navigate(`/admin/${entity}/${id}`), [id, entity]);

  const config = useMemo(() => applicationConfig.surveys.find(item => survey === item.name), [survey]);
  const questionnaireInternalConfig = useMemo(
    () => questionnaireConfig.surveys.find(item => survey === item.name),
    [survey],
  );

  const { surveyConfig, initialValues, isUpdateApplicationLoading, isGetApplicationLoading, onSubmit } =
    useUpdateApplication({
      config,
      id: Number(id),
      isAdmin: true,
      onUpdateSuccess: handleBack,
    });

  const {
    surveyConfig: questionnaireSurveyConfig,
    initialValues: questionnaireInitialValues,
    isGetQuestionnaireLoading,
    isUpdateQuestionnaireLoading,
    onSubmit: onQuestionnaireSubmit,
  } = useUpdateQuestionnaire({
    config: questionnaireInternalConfig,
    id: Number(id),
    isAdmin: true,
    onUpdateSuccess: handleBack,
  });

  const surveyTitle = t(
    `${isSurrogates ? 'application' : 'questionnaire'}.field.survey.option.${survey}.name`,
    isSurrogates ? initialValues || {} : questionnaireInitialValues || {},
  );

  const nameTitle = useMemo(() => {
    if (isSurrogates && initialValues) {
      return getName(initialValues);
    }
    if (!isSurrogates && questionnaireInitialValues) {
      return getName(
        survey === QuestionnaireSurveyEnum.secondIntendedParent
          ? questionnaireInitialValues.partner
          : questionnaireInitialValues,
      );
    }
    return '';
  }, [isSurrogates, initialValues, questionnaireInitialValues]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t(`user.page.${isSurrogates ? 'adminList' : 'parentAdminList'}.title`),
        to: `/admin/${entity}`,
      },
      {
        title: nameTitle,
        to: `/admin/${entity}/${id}`,
      },
      {
        title: surveyTitle,
      },
    ],
    [t, surveyTitle, initialValues, id, entity],
  );

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h2">{surveyTitle}</Typography>
      </Box>

      <Card
        size="large"
        img={(isSurrogates ? config : questionnaireInternalConfig)?.meta?.cardImage || defaultImageCard}
      >
        {isSurrogates && initialValues && surveyConfig && (
          <DynamicForm
            translation="application.field"
            onSubmit={onSubmit}
            onBack={handleBack}
            initialValues={initialValues}
            config={surveyConfig}
          />
        )}
        {!isSurrogates && questionnaireInitialValues && questionnaireSurveyConfig && (
          <DynamicForm
            translation="questionnaire.field"
            onSubmit={onQuestionnaireSubmit}
            onBack={handleBack}
            initialValues={questionnaireInitialValues}
            config={questionnaireSurveyConfig}
          />
        )}
      </Card>

      <Loader
        isLoading={
          isUpdateApplicationLoading ||
          isGetApplicationLoading ||
          isGetQuestionnaireLoading ||
          isUpdateQuestionnaireLoading
        }
      />
    </Box>
  );
};
