import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { File as FileDto } from '@app/file/types';
import { apolloClient } from '@app/query/configs';
import { UploadFiles } from '@app/file/components/UploadFiles';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { QUESTIONNAIRE_UPDATE_ATTACH_PHOTOS, questionnaireGetOne } from '@app/questionnaire/gql';

export interface QuestionnaireUploadFormProps {
  readonly questionnaireId: number;
}

export const QuestionnaireUploadForm = ({ questionnaireId }: QuestionnaireUploadFormProps) => {
  const { t } = useTranslation('common');
  const { data, loading, refetch } = useQuery<{ questionnaireGetOne: Questionnaire }>(
    questionnaireGetOne(['photos.name']),
    {
      variables: { id: questionnaireId },
    },
  );

  const maxFiles = 6;
  const uploadedFiles = data?.questionnaireGetOne?.photos || [];

  const handleChange = useCallback(async (photos: FileDto[]) => {
    await apolloClient.mutate<Record<any, any>>({
      mutation: QUESTIONNAIRE_UPDATE_ATTACH_PHOTOS,
      variables: {
        input: { id: questionnaireId, photos: photos.map(({ id }) => id) },
      },
    });
  }, []);

  const handleDeleted = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <UploadFiles
      name="uploadPhoto"
      type="questionnairePhoto"
      maxFiles={maxFiles}
      value={uploadedFiles}
      isLoading={loading}
      onChange={handleChange}
      onDeleted={handleDeleted}
      label={t('application.field.photos.label')}
      description={t('application.field.photos.description')}
    />
  );
};
