import { useCallback, useEffect, useMemo } from 'react';
import { camelCase, paramCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '@app/auth';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { isVisible } from '@app/dynamic/utils';
import { DynamicFormWizard } from '@app/dynamic/components/wizard/DynamicFormWizard';

import { applicationConfig } from '../configs';
import defaultImageCard from '../images/card-application-personal-info.jpg';
import { useUpdateApplication } from '../hooks/useUpdateApplication';
import { useSurveyFlag } from '../hooks/useSurveyFlag';
import { ApplicationSurveyEnum, ApplicationSurveyFlagEnum, ApplicationStatusEnum } from '../constants';

export const UserApplicationForm = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { application: currentApplication } = useAuth();
  const { survey: surveyParam } = useParams<{ survey: string }>();
  const survey = camelCase(surveyParam as string) as ApplicationSurveyEnum;

  const surveyTitle = t(`application.field.survey.option.${survey}.name`);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('application.page.title'),
        to: '/surrogate-application',
      },
      {
        title: surveyTitle,
      },
    ],
    [t, surveyTitle],
  );

  const handleFinish = useCallback(() => navigate('/surrogate-application'), [navigate]);

  const id = Number(currentApplication?.id);

  const config = useMemo(() => applicationConfig.surveys.find(item => survey === item.name), [survey]);

  const { surveyConfig, initialValues, flags, isGetApplicationLoading, onSubmit, isSurveyVisible } =
    useUpdateApplication({
      id,
      config,
      withFlags: true,
    });

  const { toggleFlag } = useSurveyFlag({ id, survey });

  const handleCompleted = useCallback(() => {
    const surveyConfigs = applicationConfig.surveys.filter(surveyItem =>
      isVisible(surveyItem, initialValues as Record<any, any>),
    );

    const nextIndex = surveyConfigs.findIndex(({ name }) => name === survey) + 1;

    const nextSurvey = surveyConfigs[nextIndex];

    if (nextSurvey) {
      navigate(`/surrogate-application/${paramCase(nextSurvey.name)}`);
    } else {
      navigate('/surrogate-application');
    }
  }, [survey, initialValues, navigate]);

  useEffect(() => {
    if (!isSurveyVisible) {
      handleCompleted();
    }
  }, [isSurveyVisible, handleCompleted]);

  useEffect(() => {
    if (flags && !flags[ApplicationSurveyFlagEnum.opened]) {
      toggleFlag(ApplicationSurveyFlagEnum.opened);
    }
  }, [flags]);

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h2">{surveyTitle}</Typography>
      </Box>

      <Card size="large" img={surveyConfig?.meta?.cardImage || defaultImageCard}>
        {initialValues && surveyConfig && (
          <DynamicFormWizard
            key={survey}
            opened={flags[ApplicationSurveyFlagEnum.opened]}
            disabled={initialValues.status !== ApplicationStatusEnum.applicants}
            translation="application.field"
            onSubmit={onSubmit}
            initialValues={initialValues}
            config={surveyConfig}
            onBack={handleFinish}
            onCompleted={handleCompleted}
          />
        )}
      </Card>

      <Box mt={3}>
        <Button color="primary" onClick={handleFinish}>
          {t('application.button.backAllSections.name')}
        </Button>
      </Box>

      <Loader isLoading={isGetApplicationLoading} />
    </Box>
  );
};
