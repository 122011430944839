export enum PreferredContactMethodEnum {
  email = 'email',
  phoneCall = 'phoneCall',
  text = 'text',
  whatsApp = 'whatsApp',
  other = 'other',
}

export enum QuestionnaireStatusEnum {
  lead = 'lead',
  available = 'available',
  matched = 'matched',
  purged = 'purged',
  inJourney = 'inJourney',
  matchFellOut = 'matchFellOut',
  exploring = 'exploring',
  comeBack = 'comeBack',
  doNotReachOut = 'doNotReachOut',
  legal = 'legal',
  ivfMock = 'ivfMock',
  postTransfer = 'postTransfer',
  obCare = 'obCare',
  postDelivery = 'postDelivery',
  completedJourney = 'completedJourney',
  applicants = 'applicants',
  completedIp = 'completedIp',
}

export enum EmbryosStatusEnum {
  ready = 'ready',
  inProgress = 'inProgress',
  looking = 'looking',
  notYet = 'notYet',
}

export enum JourneyReadinessEnum {
  newTo = 'newTo',
  readyTo = 'readyTo',
}

export enum HowDidYouHearEnum {
  referred = 'referred',
  google = 'google',
  socialMedia = 'socialMedia',
  specialEvent = 'specialEvent',
  client = 'client',
  other = 'other',
}

export enum QuestionnaireSurveyEnum {
  firstIntendedParent = 'firstIntendedParent',
  secondIntendedParent = 'secondIntendedParent',
  coupleQuestions = 'coupleQuestions',
  pathToParenthood = 'pathToParenthood',
  surrogacyJourney = 'surrogacyJourney',
  acknowledgement = 'acknowledgement',
}

export enum QuestionnaireProgressStatusEnum {
  notStarted = 'notStarted',
  completed = 'completed',
  inProgress = 'inProgress',
}

export enum EnglishLevelEnum {
  none = 'none',
  some = 'some',
  fluent = 'fluent',
}

export enum GenderIdentityEnum {
  female = 'female',
  male = 'male',
  transgenderWoman = 'transgenderWoman',
  transgenderMan = 'transgenderMan',
  nonBinary = 'nonBinary',
  other = 'other',
}

export enum PronounsEnum {
  she = 'she',
  he = 'he',
  they = 'they',
  other = 'other',
}

export enum QuestionnaireRelationshipStatusEnum {
  married = 'married',
  single = 'single',
  partnered = 'partnered',
  civilUnion = 'civilUnion',
  engaged = 'engaged',
  dating = 'dating',
  other = 'other',
}

export enum QuestionnaireDiagnosisEnum {
  chlamydia = 'chlamydia',
  gonorrhea = 'gonorrhea',
  hepatitisB = 'hepatitisB',
  hepatitisC = 'hepatitisC',
  herpes = 'herpes',
  hiv = 'hiv',
  hpv = 'hpv',
  syphilis = 'syphilis',
}

export enum QuestionnaireSurveyFlagEnum {
  opened = 'opened',
}

export enum AnnualHouseholdIncomeEnum {
  under_100k = 'under_100k',
  between_100k_200k = 'between_100k_200k',
  between_200k_300k = 'between_200k_300k',
  between_300k_400k = 'between_300k_400k',
  between_400k_500k = 'between_400k_500k',
  above_500k = 'above_500k',
}

export enum EggProviderEnum {
  intendedParent = 'intendedParent',
  eggDonor = 'eggDonor',
}

export enum ContactFrequencyEnum {
  rarely = 'rarely',
  sometimes = 'sometimes',
  veryOften = 'veryOften',
}

export enum SpermProviderEnum {
  intendedParent = 'intendedParent',
  spermDonor = 'spermDonor',
}

export enum ReduceBooleanEnum {
  yes = 'yes',
  no = 'no',
  medicalReason = 'medicalReason',
}
